import React from "react"
import { Container } from '../../library'

type Person = {
    name: string
    image: string
    role: string
    company: string
}

type P = {
    featured: Person[]
}

export const WebinarFeaturingSection = ({ featured }: P) => {
    return (
        <section className="bg-cta-bg bg-no-repeat bg-cover w-full">
            <Container className='flex flex-col text-white pt-32 gap-16 pb-20'>
                <div className='flex flex-col gap-2'>
                    <h3 className='text-2xl'>Featuring</h3>
                    <span className='w-10 bg-white h-[2px]'></span>
                </div>

                <ul className='flex gap-8 sm:gap-16 sm:px-20'>
                    {
                        featured.map(person => {
                            return (
                                <li key={person.name} className="flex flex-col gap-1 items-center">
                                    <div className='w-32 h-32 sm:w-40 sm:h-40 mb-3 rounded-full text-black justify-center items-center flex bg-white'>
                                        <img className='w-[90%] h-[90%] object-cover' src={person.image} alt={person.name} />
                                    </div>
                                    <p className='text-lg font-semibold'>{person.name}</p>
                                    <span className='flex flex-col items-center text-sm'>
                                        <p>{person.role}</p>
                                        <p>{person.company}</p>
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </Container>
        </section>
    )
}
