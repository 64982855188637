import { Link } from "gatsby"
import React, { ChangeEvent, FormEvent, useState } from "react"
import {
  Input,
  PrimaryBtn,
  InputError,
} from "../../../components/library";
import { Trans, useTranslation } from "react-i18next";

const API_URL = `https://api-dev.polymerize.io/v1/data/_webinar_registration`;

const EMAIL_PATTERN = new RegExp(
  /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
);

const nullData = {
  first_name: "",
  last_name: "",
  email: "",
  organization: "",
  phone_number: "",
  job_title: "",
  country_of_residence: "",
  country_code: "",
};

type ApiResponse = {
  result: { status: "Success" | "Failed"; message: string };
};

type P = {
  title: string;
  webinarLink: string;
  isEnded: boolean;
};

export const RegisterWebinarForm = ({ webinarLink, title, isEnded }: P) => {
  const [data, setData] = useState(nullData);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  function handleDataChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    const { value, name } = e.target;
    if (name === "email") setEmailError(null);
    setData({ ...data, [name]: value });
  }

  async function handleRegisterForWebinar(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!EMAIL_PATTERN.test(data.email))
      return setEmailError(t("webinar-registration-form.emailError"));

    setLoading(true);

    const body = JSON.stringify({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      organization: data.organization,
      link: webinarLink,
      webinar: title,
      phone_number: data.phone_number
        ? `+${data.country_code}-${data.phone_number}`
        : null,
      job_title: data.job_title,
      country_of_residence: data.country_of_residence,
    });

    try {
      const response = await fetch(API_URL, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body,
      });
      const result: ApiResponse = await response.json();
      if (result.result.status === "Failed")
        return setFormError(result.result.message);
      setSuccess(true);
      setData(nullData);
    } catch (err) {
      console.log("ERROR IN SUBMITTING REGISTER WEBINAR FORM", err);
      setFormError(t("webinar-registration-form.error"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <form
      onSubmit={handleRegisterForWebinar}
      className="bg-gradient-to-br text-[#253858] flex flex-col gap-10 p-8 rounded-md from-[#A6BFF7] to-[#FAD8BD]"
    >
      {formError && (
        <div className="flex flex-col gap-4">
          <div className="text-4xl p-4">{formError}</div>
          <PrimaryBtn
            className="w-full"
            onClick={() => {
              setSuccess(false);
              setFormError(null);
            }}
          >
            {t("common.cta.okay")}
          </PrimaryBtn>
        </div>
      )}
      {!success && !formError && (
        <>
          <div className="flex flex-col gap-4">
            <h3 className="text-3xl">
              {isEnded
                ? "Leave your Contact Details"
                : "Register for this webinar"}
            </h3>
            <p>
              {isEnded
                ? "Our customer account manager will reach out to you shortly"
                : "Reserve your seat today by entering your information below."}
            </p>
            <span className="flex flex-col sm:flex-row gap-2 w-full">
              <Input
                onChange={handleDataChange}
                name="first_name"
                placeholder={`${t(
                  "webinar-registration-form.namePlaceholder",
                )}*`}
                className="w-full"
                required
              />
              {/* <Input
                onChange={handleDataChange}
                name="last_name"
                placeholder="Last name*"
                className="w-full"
                required
              /> */}
            </span>

            <div className="flex flex-col gap-1">
              <Input
                onChange={handleDataChange}
                isError={!!emailError}
                type="email"
                name="email"
                placeholder={`${t(
                  "webinar-registration-form.emailPlaceholder",
                )}*`}
                required
              />
              {emailError && <InputError message={emailError} />}
            </div>

            <Input
              onChange={handleDataChange}
              name="organization"
              placeholder={`${t(
                "webinar-registration-form.organizationPlaceholder",
              )}*`}
              required
            />

            {/* <div className="flex gap-3 h-16">
              <Select
                name="country_code"
                className={`h-full w-32 ${
                  data.country_code ? "" : "text-gray-400"
                } ${
                  isCodeMissing ? "outline-red-400 outline outline-1" : ""
                }`}
                value={data.country_code}
                onChange={handleDataChange}
              >
                <option value="" disabled>
                  Code
                </option>

                {COUNTRIES.map((country, key) => {
                  return (
                    <option key={key} value={country.phone}>
                      {country.phone} ({country.name})
                    </option>
                  )
                })}
              </Select>
              <Input
                name="phone_number"
                type="tel"
                minLength={4}
                maxLength={14}
                validator={(value) =>
                  (+value > 0 && +value < 1000000000000000) || value === ""
                }
                value={data.phone_number}
                onChange={handleDataChange}
                className="w-full h-full"
                placeholder="Phone number"
              />
            </div> */}

            <Input
              onChange={handleDataChange}
              name="job_title"
              placeholder={`${t("webinar-registration-form.jobTitle")}*`}
            />

            {/* <Select
              name="country_of_residence"
              className={`w-full h-16 ${
                data.country_of_residence ? "" : "text-gray-400"
              }`}
              value={data.country_of_residence}
              onChange={handleDataChange}
            >
              <option value="" disabled>
                Country of residence
              </option>

              {COUNTRIES.map((country, key) => {
                return (
                  <option key={key} value={country.name}>
                    {country.name}
                  </option>
                )
              })}
            </Select> */}
          </div>

          <div className="flex flex-col gap-3 items-center">
            <PrimaryBtn
              disabled={loading}
              type="submit"
              className="w-full"
            >
              {t("common.cta.submit")}
            </PrimaryBtn>
            <span className="text-sm text-center">
              <Trans
                i18nKey={"webinar-registration-form.privacyPolicy"}
                components={{
                  Link: (
                    <Link
                      to={"/privacy-policy/"}
                      className="text-theme-blue"
                    />
                  ),
                }}
              />
            </span>
          </div>
        </>
      )}

      {success && !formError && (
        <div className="flex flex-col gap-4">
          <div className="text-3xl">
            {t("webinar-registration-form.thankYou")}
          </div>
          <span className="text-sm w-full flex-none">
            <Trans
              i18nKey={"webinar-registration-form.thankyou-subtitle"}
              components={{
                Link: (
                  <a
                    href="mailto:contact@polymerize.io"
                    className="text-theme-blue"
                  />
                ),
              }}
            />
          </span>
          <PrimaryBtn
            className="w-full"
            onClick={() => {
              setData(nullData);
              setSuccess(false);
            }}
          >
            {t("common.cta.okay")}
          </PrimaryBtn>
        </div>
      )}
    </form>
  );
};
