import React, { FormEvent, useState, ComponentPropsWithoutRef } from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { Container } from '../library'
import { mergeClasses } from '../../utils'

const API_URL = `${process.env.GATSBY_BACKEND_API_V1}/subscribe_to_newsletter`

type P = ComponentPropsWithoutRef<'section'> & {
  ignoreClasses?: string
}

export const CtaMailUpdates = ({
  className = '',
  ignoreClasses = '',
  ...props
}: P) => {
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<String | null>(null)
  const { t } = useTranslation()

  async function handleNewsletterSubscription(e: FormEvent) {
    e.preventDefault()
    try {
      setSubmitting(true)
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      })
      const data: {
        result: { status: 'Failed' | 'Success'; message: string }
      } = await response.json()

      if (data.result.status === 'Failed') return setError(data.result.message)
      setSuccess(true)
    } catch (err) {
      console.log({ err })
      setError('common.messages.internal-server-error')
    } finally {
      setEmail('')
      setSubmitting(false)
    }
  }

  return (
    <section
      className={mergeClasses({
        classes: ['bg-no-repeat bg-cta-bg bg-cover w-full', className],
        ignore: ignoreClasses
      })}
      {...props}
    >
      <Container className="flex flex-col pb-20 pt-40 text-white gap-6">
        <h2 className="text-4xl tracking-tight font-medium sm:text-5xl max-w-3xl">
          {t('cta-mail-updates.title')}
        </h2>

        <p className="text-lg">
          <Trans
            i18nKey={'cta-mail-updates.subtitle'}
            components={{ br: <br /> }}
          />
        </p>

        {success && !error && (
          <div className="w-full">{t('cta-mail-updates.success')}</div>
        )}
        {error && <div className="w-full text-red-400">{error}</div>}

        <div className="flex flex-col md:flex-row gap-4">
          {!success && !error && (
            <form
              onSubmit={handleNewsletterSubscription}
              className="flex flex-col gap-4 md:flex-row text-neutral-900"
            >
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('common.messages.enter-your-email')}
                className="px-4 py-4 border border-neutral-400 rounded w-full focus:outline-none"
                required
              />
              <button
                disabled={submitting}
                type="submit"
                className="bg-blue-700 font-semibold rounded text-white px-8 py-4 lg:py-2 shrink-0 disabled:opacity-40 disabled:cursor-not-allowed"
              >
                {submitting
                  ? `${t('common.cta.submitting')}...`
                  : t('common.cta.subscribe')}
              </button>
            </form>
          )}
        </div>
      </Container>
    </section>
  )
}
