import React from 'react'

type P =
  | {
    contentType: 'paragraph'
    title: string
    content: string | (string | JSX.Element)[] | (string | JSX.Element)[][]
    startingPara?: string
    endingParaTitle?: string
    endingParaSubtitle?: string
    benefits?: {
      icon: any
      title: string | (string | JSX.Element)[]
    }[]
  }
  | {
    contentType: 'list'
    title: string
    content: string[]
  }

export const EventArticleBlock = ({ title, ...props }: P) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-work-sans bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent max-w-max">
          {title}
        </h3>
        <span className="w-14 bg-gradient-to-r from-theme-orange to-theme-blue h-[2px]"></span>
      </div>

      {props.contentType === 'paragraph' && props.startingPara && (
        <p className="text-theme-blue">{props.startingPara}</p>
      )}

      {props.contentType === 'list' && (
        <ul className="list-disc pl-8">
          {props.content.map((line) => {
            return <li key={line.toString()}>{line}</li>
          })}
        </ul>
      )}

      {props.contentType === 'paragraph' && (
        <p className="flex flex-col gap-4">
          {typeof props.content === 'string'
            ? props.content
            : props.content.map((line) => {
              return (
                <span key={line.toString()} className="py-2">
                  {line}
                </span>
              )
            })}
        </p>
      )}
      <div className="border-t pt-4">
        {props.contentType === 'paragraph' && props.endingParaTitle && (
          <p className="text-theme-blue p-0">{props.endingParaTitle}</p>
        )}
        {props.contentType === 'paragraph' && props.endingParaSubtitle && (
          <p className="p-0">{props.endingParaSubtitle}</p>
        )}
      </div>
      {props.contentType === 'paragraph' && props.benefits && (
        <div className="lg:flex gap-4 border-b pb-4">
          {props.benefits.map((benefit) => (
            <div
              key={benefit.title.toString()}
              className="mb-8 flex flex-1 gap-4 items-center text-theme-blue"
            >
              <div>
                <img width={60} src={benefit.icon} />
              </div>
              <div>
                <p>{benefit.title}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
